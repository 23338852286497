.postal_code_container {
  height: 45px;
  label {
    margin-#{$ldirection}: 15px;
  }
  .icon {
    &--search {
      height: 15px;
      position: relative;
      #{$ldirection}: 2px;
      top: -2.7rem;
      width: 15px;
    }
    &--cheveron_small--down {
      position: relative;
      top: -4.5rem;
      float: $rdirection;
    }
  }
  .address-search-results {
    background: $color-white;
    position: absolute;
    z-index: 1;
  }
}

.sign-in-page {
  .postal_code_container {
    .icon {
      &--search {
        top: -2em;
      }
      &--cheveron_small--down {
        top: -4em;
      }
    }
    .address-search-results {
      position: sticky;
    }
  }
}

.checkout-shipping {
  .overlay-container {
    .icon {
      &--search {
        top: -1.7rem;
      }
      &--cheveron_small--down {
        top: -3.5rem;
      }
    }
  }
}
