body {
  #onetrust-pc-sdk {
    .ot-chkbox label {
      &::after {
        border: 1px solid;
        height: 16px;
        #{$ldirection}: 0;
        top: 0;
        transform: rotate(0);
        width: 18px;
      }
      &::before {
        border: 0;
      }
    }
    .ot-chkbox {
      input[type='checkbox'] {
        &:checked ~ label {
          &::before {
            background: none;
          }
        }
      }
    }
    .ot-close-icon {
      &:hover {
        background-size: 10px !important;
        height: 44px;
        width: 44px;
      }
    }
  }
  #onetrust-banner-sdk {
    .ot-sdk-container {
      padding: 20px 0;
    }
    .ot-close-icon {
      height: 12px;
      width: 44px;
      &:hover {
        @include breakpoint($landscape-up) {
          background-size: 12px !important;
        }
      }
    }
    #onetrust-close-btn-container {
      button {
        margin-top: 10px;
        top: -5px;
        &:hover {
          top: 11px;
        }
        @include breakpoint($landscape-up) {
          top: 20px;
          &:hover {
            top: 20px;
          }
        }
      }
    }
    #onetrust-button-group {
      margin-#{$rdirection}: 3%;
    }
    &.otFlat {
      .ot-sdk-row p {
        width: 90%;
      }
      &.vertical-align-content {
        #onetrust-button-group-parent {
          text-align: $rdirection;
          width: 100%;
        }
      }
      &:not(.ot-iab-2) {
        #onetrust-group-container {
          width: 100%;
        }
      }
    }
  }
}
