.cs-contact-form-formatter {
  &__form {
    display: none;
  }
  &__info--email {
    background-color: $color-black;
    color: $color-white;
    display: inline-block;
    margin-top: 10px;
    padding: 5px 10px;
    text-decoration: none;
    &:hover {
      color: $color-white;
    }
  }
}

.lp_main_ltr.lp-window-root {
  input[type='radio'] {
    position: absolute;
  }
  .lp_survey_area {
    .lp_pages_area {
      .lp_radio_button label {
        padding-#{$ldirection}: 20px;
      }
    }
  }
}
