.gnav-util {
  .gnav-account__content-container-forms {
    .facebook-login {
      display: flex;
      flex-direction: column;
      .fb-login-button {
        order: 1;
        width: 103%;
      }
      &__container {
        order: 2;
      }
      .social-login__email-opt-in {
        display: flex;
        flex-direction: column;
        order: 3;
      }
      #fb_facebook_disclaimer ~ label {
        order: 2;
        &::before {
          background: none;
        }
        &::after {
          display: none;
        }
      }
    }
  }
}

.gnav-account__content {
  .gnav-account__content-container {
    .social-login__email-opt-in {
      input[type='checkbox'] ~ label {
        padding-#{$ldirection}: 0;
      }
      div.social-login__email-promo {
        input[type='checkbox'] ~ label {
          @include swap-direction(padding, 5px 0 0 35px);
        }
      }
    }
    &-forms__signin-container-form {
      .new-member-link {
        padding-top: 10%;
      }
      &-form-field {
        padding-top: 15px;
      }
    }
  }
}
