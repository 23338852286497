@font-face {
  font-family: 'IBMPlexSansThai';
  src: url('#{$fonts-path}IBMPlexSansThai/IBMPlexSansThai-Regular.woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSansThai';
  src: url('#{$fonts-path}IBMPlexSansThai/IBMPlexSansThai-Medium.woff2');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'IBMPlexSansThai';
  src: url('#{$fonts-path}IBMPlexSansThai/IBMPlexSansThai-SemiBold.woff2');
  font-weight: bold;
  font-display: swap;
}
